import React, { useEffect } from "react";
import { LazyMotion } from "framer-motion";

import { useAppState } from "@state";
import loadable from "@loadable/component";

const LakotaAcHeader = loadable(() => import("@organisms/LakotaAcHeader"));
const Themer = loadable(() => import("../atoms/Themer"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};
const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children }) => {
  const [{ layout }] = useAppState();
  const { theme } = layout;

  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);
  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div
        id="app_layout"
        className={`lakota transition duration-500 ${
          theme === "light" ? "bg-cream" : "bg-brown"
        }`}
      >
        <LakotaAcHeader />
        {/* <LakotaPageContainer path={path}> */}
        {children}
        {/* </LakotaPageContainer> */}
        <div id="fb-root" />
        <Themer />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
